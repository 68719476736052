import React, { useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from "gatsby"

const WhatsNew = () => {
  useEffect(() => {
      if (window.location.href.indexOf('#whatsnew') !== -1) {
        console.log('use effect in what\'s new');
        navigate('/#whatsnew');
      }
  }, []);
  return (
      <section id='whatsnew'>
        <div className='row'>
          <div className='twelve columns main-col'>
            <div className='row item'>
              <div className='twelve columns'>
                <div className="main-eye-catch-0" style={{ "display": "none"}}>
                  <iframe width="95%" height="350px" src="https://www.youtube.com/embed/BbxtNbLVVc8" title="2022.9.28&29 JUNKO MORIYA ORCHESTRA 2Days with ASAKO TOKI リハーサル映像" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              <div>
            <h2>What's New</h2>
                <h3>時々動画をあげています        <a href="https://www.youtube.com/channel/UCxhSx5bX4N9xxcaMJSkWpPg">
          <i className="fa fa-youtube"></i>
        </a>
</h3>
                <p><a href="https://www.youtube.com/channel/UCxhSx5bX4N9xxcaMJSkWpPg">Junko Moriya Channel</a>をご覧ください。<br/>チャンネル登録もよろしくお願いいたします。</p>
</div>
              </div>
              <hr class="hr-top"/>
            </div>


            <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem"}}>
                <h3>ライン登録はこちら</h3>
                <p>
                 <a href="https://lin.ee/5gcJt9E"><img style={{height: 33}} src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"/></a>
                </p>
              </div>
              <hr/>
            </div>




{/*
       <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem", "margin-bottom": "2rem"}}>
　　　　　　　    <StaticImage className="main-eye-catch" style={{"width":"50%", "margin-right":"2rem"}}
　　　　　　        alt="BODY&SOUL 2024.0906"
　　　　　　        src="../images2/24906_body&soul.jpg"
　　　　　　      />
                <h3>Junko Moriya Sextet @渋谷公園通りBODY&SOUL</h3>
                <p>2024.09.06(金) 渋谷公園通りBODY&SOUL<br/>
                open18:30--/ 1st 19:30-,2nd 21:00--入替なし <br/>
                MC. 一般4600円、学生税込3000円</p>
                <p>
日本を代表する老舗ジャズクラブ・BODY&SOUL50周年記念の特別ライブです。単独のオーナー（関京子ママ）による50年継続のジャズクラブは、世界的にもとても珍しいです。豪華Sextetとご一緒に、お祝いしましょう！<a href="https://www.bodyandsoul.co.jp/event/240906">2024.09.06　BODY&SOUL50周年記念特別ライブ！/Junko Moriya Sextet</a>
                </p>
              </div>
              <hr/>
            </div>

*/}


              <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem"}}>
　　　　　　　       <h3>Junko Moriya Orchestra@Jazz Dining B-flat </h3>
                <p>
2024.11.29(金) 赤坂Jazz Dining B-flat <br/>open18:00--/ 1st 19:00-,2nd 20:15--入替なし <br/>
                 一般　4,400円(税込4,840円）,学生2,500円（税込2,750円）</p>
                <p>
『守屋純子オーケストラ』が赤坂B-flatに最登場！多くの社会人・学生ビッグバンド に取り上げられているオリジナルや、新解釈のスタンダードなど・・・絶対にここでしか出会えない音にご期待ください！美味しいお料理やお酒も楽しんでいただけます。<br/>

<a href="https://bflat.yamano-music.co.jp/event?contentId=a5c6j4mlhg">2024.9.29　Junko Moriya Orchestra@Jazz Dining B-flat</a>
                </p>
              </div>
              <hr/>
            </div>




{/*

            <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem"}}>
                <h3>早稲田大学エクステンション＜ジャズを楽しむ＞2023春期講座</h3>
                <p>
早稲田大学エクステンション＜ジャズを楽しむ＞春季講座が始まります！2023.5/9より、毎週火曜日午後1時—午後2時半、全5回です。アーカイブがありますので、この時間にご都合が悪くても、全国どこからでも受講することができます。詳細はこちらです。なお、お申し込みの際に「教員紹介」とお伝えいただくと、入会金割引の対象となります。
入会金は4年度間有効で、会員料金で講座を受講いただけるほか、早稲田大学中央図書館の利用可など、さまざまな特典があります。（入会しなくても、受講できます）<br/>
                <a href="https://www.wuext.waseda.jp/course/detail/58336/">https://www.wuext.waseda.jp/course/detail/58336/</a>
                </p>
              </div>
              <hr/>

            </div>
*/}


           <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem", "margin-bottom": "2rem"}}>
　　　　　　　    <StaticImage className="main-eye-catch-l" style={{"width":"50%", "margin-right":"2rem"}}
　　　　　　        alt="長津田ハーモナイズポケット"
　　　　　　        src="../images2/harmonizep.jpeg"
　　　　　　 　　　  />
                <h3>『2024.9.22(日)守屋純子trio@長津田ハーモナイズポケット』</h3>
　       <p><b>2024.9/22（日)長津田ハーモナイズポケット　open:14:30-- /Start:15:00(17:00頃終了）</b><br/> 
               MC:3000円</p>
                <p>
長津田駅南口徒歩2分にあるすてきなジャズカフェで贅沢な時間を！初出演です。メンバー：岡崎正典(TS)、佐藤慎一(B)<a href="https://www.instagram.com/harmonizedpocket/">2024.9.22 守屋純子TRIO</a>
                </p>
              </div>
              <hr/>
            </div>




{/*
           <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem", "margin-bottom": "2rem"}}>
　　　　　　　    <StaticImage className="main-eye-catch-l" style={{"width":"50%", "margin-right":"2rem"}}
　　　　　　        alt="瀬川昌久さん生誕100周年コンサート"
　　　　　　        src="../images2/「瀬川さん生誕100年コンサート」.jpeg"
　　　　　　 　　　  />
                <h3>『2024.6.29(土)瀬川昌久さん　生誕100年コンサート』</h3>
                <p><b>2024.6/29(土) ムーブ町屋ホール　　open:16:30--  /Start: 17:00--</b><br/> 
               MC:4000円、学生 2000円（前売）　　当日は500円up　</p>
                <p>
池本茂貴(TB)氏と守屋による、この日のためのみの特別編成ビッグバンド＜Segawa Memorial Jazz Orchestra＞にて出演します。 <a href="https://commemorating-mr-segawa.fusanet.com/">2024.06.29(土)./瀬川昌久さん生誕100年コンサート</a>
                </p>
              </div>
              <hr/>
            </div>


 */}

{/*
            <div className='row item'>
              <div className='twelve columns'>
                <h3>2024.7.19(金)守屋純子＆山田貴子@三鷹ナチュラル</h3>
<iframe width="560" height="315" src="https://www.youtube.com/embed/QOMviy6aQ1c?si=irG7Pu4hA44Mr7M-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
              <hr/>
            </div> 


 */}

          </div>
        </div>
      </section>

  );
}


export default WhatsNew
